.videoBG{
 display: flex;
 position: relative;
 flex-direction: column;
 align-items: center;
 margin: auto;
 margin-bottom: 50px;
 
 @media screen and (max-width: 768px){
     max-height: 360px;
     max-width: 360px;
 }
}