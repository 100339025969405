@import "../../global.scss";

.portfolio {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  .hidden {
    display: none;
  }
  h1 {
    font-size: 60px;
    margin-bottom: 10px;
    color: #c13b3a;
    font-weight: bold;

    @include mobile {
      font-size: 2rem;
    }
  }
  h4 {
    font-size: 30px;
    padding-top: 0px;
    color: #c13b3a;
    font-weight: 400;

    @include mobile {
      font-size: 1.5rem;
      margin-top: 10px;
    }
  }

  ul {
    margin: 10px;
    padding: 0;
    list-style: none;
    display: flex;

    @include mobile {
      margin: 10px 0;
      flex-wrap: wrap;
      justify-content: center;
    }
  }

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    overflow: hidden;
    background-color: white;

    @include mobile {
      width: 100%;
    }

    .item {
      width: 600px;
      height: 600px;
      border-radius: 20px;
      border: 1px solid rgb(240, 239, 239);
      margin: 5px 5px;
      margin-bottom: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      position: relative;
      transition: all 0.5s ease;
      @media not all and (min-resolution:.001dpcm) {
        @supports (-webkit-appearance: none) {
          width: 600px;
          height: 600px;
          border-radius: 20px;
          border: 1px solid rgb(240, 239, 239);
          margin: 5px 5px;
          margin-bottom: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          color: white;
          position: relative;
          transition: all 0.5s ease;
        }
      }

      @include mobile {
        width: 420px;
        height: 300px;
      }
      

      h3 {
        position: absolute;
        font-size: 20px;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 1;
      }
    }
  }
  .buttonList {
    font-size: 14px;
    margin-right: 50px;
    padding: 7px;
    border-radius: 10px;
    cursor: pointer;

    @include mobile {
      margin-right: 20px;
    }

    &.active {
      background-color: #c13b3a;
      color: white;
    }
  }
}
