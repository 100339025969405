@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap);
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.portfolioList{font-size:14px;margin-right:50px;padding:7px;border-radius:10px;cursor:pointer}@media(max-width: 768px){.portfolioList{margin-right:20px}}.portfolioList.active{background-color:#c13b3a;color:#fff}
.portfolio{background-color:#fff;display:flex;flex-direction:column;align-items:center;overflow:hidden}.portfolio .hidden{display:none}.portfolio h1{font-size:60px;margin-bottom:10px;color:#c13b3a;font-weight:bold}@media(max-width: 768px){.portfolio h1{font-size:2rem}}.portfolio h4{font-size:30px;padding-top:0px;color:#c13b3a;font-weight:400}@media(max-width: 768px){.portfolio h4{font-size:1.5rem;margin-top:10px}}.portfolio ul{margin:10px;padding:0;list-style:none;display:flex}@media(max-width: 768px){.portfolio ul{margin:10px 0;flex-wrap:wrap;justify-content:center}}.portfolio .container{width:100%;display:flex;align-items:center;justify-content:center;flex-wrap:wrap;overflow:hidden;background-color:#fff}@media(max-width: 768px){.portfolio .container{width:100%}}.portfolio .container .item{width:600px;height:600px;border-radius:20px;border:1px solid #f0efef;margin:5px 5px;margin-bottom:auto;display:flex;align-items:center;justify-content:center;color:#fff;position:relative;transition:all .5s ease}@media not all and (min-resolution: 0.001dpcm){@supports(-webkit-appearance: none){.portfolio .container .item{width:600px;height:600px;border-radius:20px;border:1px solid #f0efef;margin:5px 5px;margin-bottom:auto;display:flex;align-items:center;justify-content:center;color:#fff;position:relative;transition:all .5s ease}}}@media(max-width: 768px){.portfolio .container .item{width:420px;height:300px}}.portfolio .container .item h3{position:absolute;font-size:20px}.portfolio .container .item img{width:100%;height:100%;object-fit:cover;z-index:1}.portfolio .buttonList{font-size:14px;margin-right:50px;padding:7px;border-radius:10px;cursor:pointer}@media(max-width: 768px){.portfolio .buttonList{margin-right:20px}}.portfolio .buttonList.active{background-color:#c13b3a;color:#fff}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
input,
textarea {
  font-family: "Gotham", sans-serif;
}

.container {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 2rem;
  background-color: white;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 100%;
  max-width: 820px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.1);
  z-index: 5;
  overflow: hidden;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.contact-form {
  background-color: #c13b3a;
  position: relative;
}

.circle {
  border-radius: 50%;
  background: linear-gradient(135deg, transparent 20%, #149279);
  position: absolute;
}

.circle.one {
  width: 130px;
  height: 130px;
  top: 130px;
  right: -40px;
}

.circle.two {
  width: 80px;
  height: 80px;
  top: 10px;
  right: 30px;
}

.contact-form:before {
  content: "";
  position: absolute;
  width: 26px;
  height: 26px;
  background-color: #c13b3a;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  top: 50px;
  left: -13px;
}

form {
  padding: 2.3rem 2.2rem;
  z-index: 10;
  overflow: hidden;
  position: relative;
}

.title {
  color: #fff;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1;
  margin-bottom: 0.7rem;
}

.input-container {
  position: relative;
  margin: 1rem 0;
  color: white;
}

.input {
  width: 100%;
  outline: none;
  border: 2px solid #fafafa;
  background: white;
  padding: 0.6rem 1.2rem;
  color: black;
  font-weight: 500;
  font-size: 0.95rem;
  letter-spacing: 0.5px;
  border-radius: 25px;
  transition: 0.3s;
}

textarea.input {
  padding: 0.8rem 1.2rem;
  min-height: 150px;
  border-radius: 22px;
  resize: none;
  overflow-y: auto;
  color: black;
}

.input-container label {
  position: absolute;
  top: 50%;
  left: 15px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  padding: 0 0.4rem;
  color: #fafafa;
  font-size: 0.9rem;
  font-weight: 400;
  pointer-events: none;
  z-index: 5;
  transition: 0.5s;
}

.input-container.textarea label {
  top: 1rem;
  -webkit-transform: translateY(0);
          transform: translateY(0);
}

.btn {
  padding: 0.6rem 1.3rem;
  background-color: #fff;
  border: 2px solid #fafafa;
  font-size: 0.95rem;
  color: #c13b3a;
  line-height: 1;
  border-radius: 25px;
  outline: none;
  cursor: pointer;
  transition: 0.3s;
  margin: 0;
}

.btn:hover {
  background-color: transparent;
  color: #fff;
}

.input-container span {
  position: absolute;
  top: 0;
  left: 25px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  font-size: 0.8rem;
  padding: 0 0.4rem;
  color: transparent;
  pointer-events: none;
  z-index: 5;
}

.input-container span:before,
.input-container span:after {
  content: "";
  position: absolute;
  width: 10%;
  opacity: 0;
  transition: 0.3s;
  height: 5px;
  background-color: #c13b3a;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.input-container span:before {
  left: 50%;
}

.input-container span:after {
  right: 50%;
}

.input-container.focus label {
  top: 0;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  left: 25px;
  font-size: 0.8rem;
}

.input-container.focus span:before,
.input-container.focus span:after {
  width: 50%;
  opacity: 1;
}

.contact-info {
  padding: 2.6rem 2.2rem;
  position: relative;
}

.contact-info .title {
  color: #c13b3a;
  opacity: 0.9;
  
}

.text {
  color: #333;
  margin: 1.5rem 0 2rem 0;
}

.information {
  display: flex;
  color: #555;
  margin: 0.7rem 0;
  align-items: center;
  font-size: 0.95rem;
}

.icon {
  width: 37px;
  margin-right: 0.8rem;
}

.social-media {
  padding: 150px 0px 20px ;

}

.social-media p {
  color: #333;
  font-size:small;
}

.social-icons {
  display: flex;
  margin-top: 0.5rem;
}

.social-icons a {
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: linear-gradient(45deg, #c13b3a, #c13b3a);
  color: #fff;
  text-align: center;
  line-height: 35px;
  margin-right: 0.5rem;
  transition: 0.3s;
}

.social-icons a:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.contact-info:before {
  content: "";
  position: absolute;
  width: 110px;
  height: 100px;
  border: 22px solid #c13b3a;
  border-radius: 50%;
  bottom: -77px;
  right: 50px;
  opacity: 0.3;
}

.big-circle {
  position: absolute;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(to bottom, #c13b3a, #c13b3a);
  bottom: 50%;
  right: 50%;
  -webkit-transform: translate(-40%, 38%);
          transform: translate(-40%, 38%);
}

.big-circle:after {
  content: "";
  position: absolute;
  width: 360px;
  height: 360px;
  background-color: #fafafa;
  border-radius: 50%;
  top: calc(50% - 180px);
  left: calc(50% - 180px);
}

.square {
  position: absolute;
  height: 400px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(181%, 11%);
          transform: translate(181%, 11%);
  opacity: 0.2;
}

@media (max-width: 850px) {
  .form {
    grid-template-columns: 1fr;
  }

  .contact-info:before {
    bottom: auto;
    bottom: initial;
    top: -75px;
    right: 65px;
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }

  .contact-form:before {
    top: -13px;
    left: auto;
    left: initial;
    right: 70px;
  }

  .square {
    -webkit-transform: translate(140%, 43%);
            transform: translate(140%, 43%);
    height: 350px;
  }

  .big-circle {
    bottom: 75%;
    -webkit-transform: scale(0.9) translate(-40%, 30%);
            transform: scale(0.9) translate(-40%, 30%);
    right: 50%;
  }

  .text {
    margin: 1rem 0 1.5rem 0;
  }

  .social-media {
    padding: 1.5rem 0 0 0;
  }
}

@media (max-width: 480px) {
  .container {
    padding: 1.5rem;
  }

  .contact-info:before {
    display: none;
  }

  .square,
  .big-circle {
    display: none;
  }

  form,
  .contact-info {
    padding: 1.7rem 1.6rem;
  }

  .text,
  .information,
  .social-media p {
    font-size: 0.8rem;
  }

  .title {
    font-size: 1.15rem;
  }

  .social-icons a {
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .icon {
    width: 23px;
  }

  .input {
    padding: 0.45rem 1.2rem;
    background-color: white;
  }

  .btn {
    padding: 0.45rem 1.2rem;
  }
  .placeholder {
    color: #fff;
  }
}
.videoBG{display:flex;position:relative;flex-direction:column;align-items:center;margin:auto;margin-bottom:50px}@media screen and (max-width: 768px){.videoBG{max-height:360px;max-width:360px}}
